import React , { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/fake-data/logo';

const Footer = () => {
    const { t, i18n } = useTranslation();

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img src={logo} alt="Mekabeta" data-aos="fade-down" />
                      </Link>
                        
                        <p className="desc fs-18" data-aos="fade-up">
                          {t('footer.desc')}
                        </p>  
                        <ul className="social">
                            <li data-aos="fade-up" data-aos-duration="1200"><a href="https://twitter.com/mekabeta21707" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                            <li data-aos="fade-up" data-aos-duration="1400"><Link to="#"><i className="fab fa-telegram-plane"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1600"><Link to="#"><i className="fab fa-youtube"></i></Link></li>
                            <li data-aos="fade-up" data-aos-duration="1000"><Link to="#"><i className="fab fa-discord"></i></Link></li>
                        </ul>
                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2024, Mekabeta - Odd Even Bet blockchain game. </p>
                        <ul>
                            <li><Link to="#">Terms & Condition</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Cookie Policy</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Footer;