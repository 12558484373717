const dataRoadMap = [
    {
        title: 'roadTitle01',
        text: 'roadText01',
        time: 'roadTime01',
        class: 'top'
    },
    {
        title: 'roadTitle02',
        text: 'roadText02',
        time: 'roadTime02',
        class: 'bottom'
    },
    {
        title: 'roadTitle03',
        text: 'roadText03',
        time: 'roadTime03',
        class: 'top'
    },
    {
        title: 'roadTitle04',
        text: 'roadText04',
        time: 'roadTime04',
        class: 'bottom'
    },
    {
        title: 'roadTitle05',
        text: 'roadText05',
        time: 'roadTime05',
        class: 'top'
    },
]

export default dataRoadMap;