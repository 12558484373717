const menus = [
    {
        id: 1,
        name: 'play',
        links: '/',
    },
    {
        id: 2,
        name: 'invitation',
        links: '#invitation'
    },{
        id: 3,
        name: 'features',
        links: '#features'
    },
    {
        id: 4,
        name: 'roadmap',
        links: '#roadmap',
    },
    {
        id: 5,
        name: 'faq',
        links: '#faq'
    },
    {
        id: 6,
        name: 'language',
        links: '#',
        namesub: [
            {
                id: 1,
                sub: 'English',
                links: '/?lang=en_us'
            },
            {
                id: 2,
                sub: '简体中文',
                links: '/?lang=zh_cn'
            },
            {
                id: 3,
                sub: '繁體中文',
                links: '/?lang=zh_tw'
            },
            {
                id: 4,
                sub: '한국인',
                links: '/?lang=ko_kr'
            }
        ]
    },
]

export default menus;