import { ethers } from "ethers";
import MekaGameJson from '../abi/MekaGame.json';

export async function getBetTable(contract, isOdd, provider) {
    if (!contract || !provider) {
        return undefined;
    }
    let signer = await provider.getSigner();
    let gameContract = new ethers.Contract(contract, MekaGameJson, signer);
    let table = await gameContract.printBetTable(isOdd);
    return table;
}

export async function getBetAmount(contract, provider) {
    if (!contract || !provider) {
        return {
            period: 0,
            oddAmt: 0,
            evenAmt: 0
        };
    }
    let signer = await provider.getSigner();
    let gameContract = new ethers.Contract(contract, MekaGameJson, signer);
    let betAmount = await gameContract.printBetAmount();
    let period = betAmount[0].toString();
    let oddAmt = ethers.utils.formatEther(betAmount[1].toString());
    let evenAmt = ethers.utils.formatEther(betAmount[2].toString());
    return {
        period,
        oddAmt,
        evenAmt
    };
}

export async function getRemainBetCnt(contract, provider) {
    if (!contract || !provider) {
        return 0;
    }
    let signer = await provider.getSigner();
    let gameContract = new ethers.Contract(contract, MekaGameJson, signer);
    let betAmount = await gameContract.remainBetCnt();
    return betAmount.toNumber();
} 

export async function getMyInvitor(contract, provider, address) {
    if (!contract || !provider) {
        return undefined;
    }
    let signer = await provider.getSigner();
    let gameContract = new ethers.Contract(contract, MekaGameJson, signer);
    let invitor = await gameContract.invitorMap(address);
    return invitor;
} 