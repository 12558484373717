import GoerliConfig from './goerli';
import LocalConfig from './local';
import EthereumConfig from './ethereum';
import MumbaiConfig from './mumbai';
import BscTestnetConfig from './bsc_testnet';
import BlastTestnetConfig from './blast_testnet';

export {GoerliConfig, LocalConfig, EthereumConfig};

export function getNetworkConfig(chainId) {
    switch (chainId) {
        case 1:
            return EthereumConfig;
        case 5:
            return GoerliConfig;
        case 1337:
            return LocalConfig;
        case 80001:
            return MumbaiConfig;
        case 97:
            return BscTestnetConfig;
        case 168587773:
            return BlastTestnetConfig;
        default:
            return undefined;
    }
}