export const BlastTestnet = {
  chainId: 168587773,
  chainName: 'Blast Sepolia',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  getExplorerAddressLink: (address) => `https://testnet.blastscan.io/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://testnet.blastscan.io/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://sepolia.blast.io',
  blockExplorerUrl: 'https://testnet.blastscan.io',
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  }
}