import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const GameFeatures = (props) => {

    const { t, i18n } = useTranslation();

    const data = props.data;

    return (
        <section className="tf-section section-speciality" id="features" style={{marginTop: '240px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="block-text pt-128 pd-0">
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{t('features.subtitle')}</h5>
                            <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">{t('features.title')}</h3>
                            <p className="fs-21" data-aos="fade-up" data-aos-duration="1000">{t('features.desc')}</p>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="section-speciality__box">
                            {
                                data.map((data,index) => (
                                    <div key={index} className={`box-item bg-2 ${data.class}`} data-aos="flip-left">
                                        <h3 className='color-main'>{data.stt}</h3>
                                        <Link to="#" className="h5">{t(`features.${data.title}`)}</Link>
                                        <p>{t(`features.${data.desc}`)}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GameFeatures;