import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import dataFaq from '../assets/fake-data/data-faq';
import dataRoadMap from '../assets/fake-data/data-roadmap2';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header'
import Invitation from '../components/layouts/Invitation';
import FAQ3 from '../components/layouts/FAQ3';
import RoadMap2 from '../components/layouts/RoadMap2';
import SliderThree from '../components/slider/SliderThree';
import GameFeatures from '../components/layouts/GameFeatures';
import dataCard from '../assets/fake-data/data-card';

const Home03 = () => {
  let {search} = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (search) {
        let parsed = queryString.parse(search);
        if (parsed && parsed.lang) {
            i18n.changeLanguage(parsed.lang);
        }
    }
  }, [search])

  return (
    <div className='home-3'>
        <Header />
        <SliderThree />
        <Invitation />
        <GameFeatures data={dataCard} />
        <RoadMap2 data={dataRoadMap} />
        <FAQ3 data={dataFaq} />
        <Footer />
    </div>
  );
}

export default Home03;