import { configureStore } from '@reduxjs/toolkit'
import chainReducer from './redux/chainSlice'
import logger from 'redux-logger'

const reducer = {
  chain: chainReducer,
};

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => process.env.NODE_ENV === 'production' ? getDefaultMiddleware() : getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
})