import React, { useEffect , useState } from 'react';
import Button from 'react-bootstrap/Button';
import "./metamask_connect.scss"
import { useEthers } from '@usedapp/core'
import { formatAddress } from '../../utils';

const MetamaskConnect = () => {
    let [btnText, setBtnText] = useState("Connect Wallet");
    let { account: address, activateBrowserWallet } = useEthers();

    useEffect(() => {
        if (address) {
            setBtnText(formatAddress(address));
        }
    }, [address])

    return (
        <Button variant="primary" onClick={() => activateBrowserWallet()} active>
            {btnText}
        </Button>
    )
}

export default MetamaskConnect;