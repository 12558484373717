import { createSlice } from '@reduxjs/toolkit'
import { Mainnet } from '@usedapp/core'
import {getNetworkConfig} from '../config'

export const chainSlice = createSlice({
  name: 'chain',
  initialState: {
    activeChainId: Mainnet.chainId,
    balanceSymbol: "ETH",
    mekaGame: getNetworkConfig(Mainnet.chainId)?.MEKA_CONTRACT,
  },
  reducers: {
    changeChain: (state, action) => {
      let chainId = action.payload.chainId;
      state.activeChainId = chainId;
      if (chainId !== undefined) {
        let config = getNetworkConfig(chainId);
        state.mekaGame = config?.MEKA_CONTRACT;
      }
    },
    updateBalanceSymbol: (state, action) => {
      state.balanceSymbol = action.payload.symbol;
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeChain, updateBalanceSymbol } = chainSlice.actions

export default chainSlice.reducer