const dataFaq = [
    {
        title: 'faqTitle01',
        text: 'faqText01',
        show: 'true'
    },
    {
        title: 'faqTitle02',
        text: 'faqText02',

    },
    {
        title: 'faqTitle03',
        text: 'faqText03'
    },
    {
        title: 'faqTitle04',
        text: 'faqText04'
    },
    {
        title: 'faqTitle05',
        text: 'faqText05'
    },
]

export default dataFaq;