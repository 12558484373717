import { useCall, useEthers } from '@usedapp/core'
import { ethers } from 'ethers'
import MekaGameJson from '../abi/MekaGame.json';

export default function useTriggerNum(contractAddress) {
  const { library: provider } = useEthers();
  const { value, error } =
      useCall(
        contractAddress && provider && {
          contract: new ethers.Contract(contractAddress, MekaGameJson, provider),
          method: "triggerNum", // Method to be called
          args: [], // Method arguments
        }
      ) ?? {};
    if(error) {
      // console.error(error.message)
      return undefined
    }
    return value?.[0].toNumber()
}