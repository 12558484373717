import { Arbitrum, Mumbai, Polygon } from "@usedapp/core";
import { BlastTestnet } from "./BlastTestnetChain";

export function formatAddress(address) {
    if (address == null || address === undefined) {
        return "";
    }
    let prefix = address.slice(0, 6);
    let endfix = address.slice(-4);
    return prefix + "..." + endfix;
}

export function formatBalance(value) {
    return Number(value).toFixed(5);
}

export function formatUrlConcat(prefix, endfix) {
    let a = prefix.charAt(prefix.length-1);
    if (a === '/') {
        return prefix + endfix;
    }
    return prefix + "/" + endfix;
}

export function getChainName(chainId) {
    switch (chainId) {
        case 1:
            return "Ethereum";
        case "0x89":
            return "Polygon";
        case "0x38":
            return "BSC Mainnet";
        default:
            return undefined;
    }
}

export function getChainNativeSymbol(chainId) {
    switch (chainId) {
        case Arbitrum.chainId:
        case BlastTestnet.chainId:
            return "ETH";
        case Polygon.chainId:
        case Mumbai.chainId:
            return "Matic";
        default:
            return undefined;
    }
}