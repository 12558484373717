import React , {useState , useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { FaClone } from "react-icons/fa";
import {formatAddress, formatBalance, getChainNativeSymbol} from '../../utils'
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { ethers } from 'ethers';
import { useEthers, useEtherBalance, useSendTransaction } from '@usedapp/core'
import useInterval from '../../hooks/useInterval';
import usePrintBetAmount from '../../hooks/usePrintBetAmount';
import useTriggerNum from '../../hooks/useTriggerNum';
import { getRemainBetCnt, getBetAmount } from '../../fetch/fetchGame';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {changeChain, updateBalanceSymbol} from '../../redux/chainSlice'
import { useTranslation } from 'react-i18next';

const gameStartDate = moment("2024-01-11 00:00:00", "YYYY-MM-DD HH:mm:ss");

const SliderThree = () => {

    const dispatch = useDispatch();

    let [runDays, setRunDays] = useState(0);
    let [betInfo, setBetInfo] = useState({period: 0, oddAmt: 0.0, evenAmt: 0.0});
    let [remainBets, setRemainBets] = useState(30);
    let [triggerNum, setTriggerNum] = useState(0);
    let [amount, setBetAmount] = useState(0);
    let [balance, setBalance] = useState({value: 0, symbol: 'ETH'});
    let [isMismatched, setIsMismatched] = useState(0);
    let activeChainId = useSelector((state) => state.chain.activeChainId)
    let mekaGame = useSelector((state) => state.chain.mekaGame)

    const { t, i18n } = useTranslation();

    const { account, chainId, library, error } = useEthers();
    const betAmount = usePrintBetAmount(mekaGame);
    const triggerCnt = useTriggerNum(mekaGame);
    const userBalance = useEtherBalance(account);
    const { sendTransaction, state: txState } = useSendTransaction();

    useEffect(() => {
        if (error) {
            setIsMismatched(1);
        }
    }, [error])

    useInterval(async () => {
        let remainCnt = await getRemainBetCnt(mekaGame, library);
        let betAmt = await getBetAmount(mekaGame, library);
        setBetInfo(betAmt);

        setRemainBets(remainCnt);
    }, 5000);

    useEffect(() => {
        async function updateRemainBets() {
            let remainCnt = await getRemainBetCnt(mekaGame, library);
            setRemainBets(remainCnt);
        }
        updateRemainBets();
        if (chainId !== undefined && userBalance) {
            setBalance({value: ethers.utils.formatEther(userBalance?.toString()), symbol: "ETH"});
            // dispatch(updateBalanceSymbol({symbol: "ETH"}))
        }
        if (betAmount) {
            setBetInfo({
                period: betAmount[0].toString(), 
                oddAmt: ethers.utils.formatEther(betAmount[1].toString()), 
                evenAmt: ethers.utils.formatEther(betAmount[2].toString())
            });
        } else {
            setBetInfo({period: 0, oddAmt: 0, evenAmt: 0});
        }
        if (triggerCnt) {
            setTriggerNum(triggerCnt);
        } else {
            setTriggerNum(15);
        }
    }, [chainId, library, mekaGame, betAmount, triggerCnt, userBalance]);

    useEffect(() => {
        if (activeChainId !== chainId) {
            dispatch(changeChain({chainId}));
        }
    }, [chainId, activeChainId, dispatch]);

    useEffect(() => {
        let nowdate = moment();
        let days = nowdate.diff(gameStartDate, "day");
        setRunDays(days);
    }, []);

    useEffect(() => {
        if (txState.status === 'Fail' || txState.status === 'Exception') {
            if (txState.errorMessage.indexOf('insufficient funds') !== -1) {
                toast.error(t('slider.insufficientFunds'))
            } else {
                toast.error(txState.errorMessage)
            }
        }
    }, [txState])

    const copyAddress = () => {
        copy(mekaGame);
        toast.success(t('slider.copySuccess'));
    };

    const bet = async (e) => {
        e.preventDefault();
        
        if (isNaN(amount)) {
            toast.error(t('slider.numberTip'));
            return;
        }
        let amt = Number(amount).toString();

        if (chainId === undefined) {
            toast.error(t('slider.connectWalletTip'));
            return;
        }
        if (isMismatched) {
            toast.error(t('slider.unsupportedNetwork'));
            return;
        }

        let ret = await sendTransaction({ to: mekaGame, value: ethers.utils.parseEther(amt).toString() });
        if (ret && ret.confirmations >= 1) {
            toast.success(t('slider.betSuccess'))
        }
    }

    return (
    
    <section className="tf-section hero-slider">
        <div className="container">
            <div className="row">
                <div className="col-xl-5 col-md-12 col-12">
                    <div className="block-text pt-24" >
                        <h6 className="sub-title mb-6" data-aos="fade-up">{t('slider.title')}</h6>
                        <h3 className="title mb-26" data-aos="fade-up">{t('slider.header', {days: runDays})}</h3>
                        <p className="desc mb-43" data-aos="fade-up">{t('slider.gameDesc', {triggerNum})}</p>
                    </div>
                </div>
                <div className="col-xl-7 col-md-12 col-12">
                    <div className="token-bet" data-aos="zoom-in" data-aos-duration="2000">
                        <h5 className="fs-38">#{betInfo.period} {t('slider.triggerTips', {remainBets})}</h5>
                        <div className="tf-bettable-bar">
                        <div className="descrip">
                                <h6 className="fs-14">{betInfo.oddAmt}<span>{t('slider.oddTips')}</span></h6>
                                <h6 className="fs-14">{betInfo.evenAmt}<span>{t('slider.evenTips')}</span></h6>
                            </div>
                            <BetTable oddAmt={betInfo.oddAmt} evenAmt={betInfo.evenAmt} />
                        </div>
                        <div className='bet-tips'>{t('slider.betTips')}</div>
                        <div className='contract-address-container'>
                            <span>{t('slider.contractAddress')}{formatAddress(mekaGame)}</span>
                            <span onClick={copyAddress}><FaClone color='#fd562a'/></span>
                        </div>
                        
                        <div className="button-action">
                            <Form>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder={t('slider.betInputTips')}
                                        style={{borderColor: '#fd562a', 
                                            borderRadius: '10px 0 0 10px', 
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            color: '#fff'
                                        }}
                                        onChange={(v) => setBetAmount(v.target.value)}
                                    />
                                    <Link className="btn-action style-2" onClick={bet}>{t('slider.betBtn')}</Link>
                                </InputGroup>
                            </Form>
                        </div>
                        <div className='fs-14'>
                            <span>{t('slider.walletBalance')}{formatBalance(balance.value)} {getChainNativeSymbol(chainId)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

const BetTable = ({oddAmt, evenAmt}) => {
    const [oddStyle, setOddStyle] = useState();
    const [evenStyle, setEvenStyle] = useState();
    const [borderStyle, setBorderStyle] = useState();

    useEffect(() => {
        if (oddAmt > evenAmt) {
            setBorderStyle({
                borderColor: '#fd562a'
            })
        } else if (oddAmt < evenAmt) {
            setBorderStyle({
                borderColor: '#3BFD2A'
            })
        } else {
            setBorderStyle({
                borderColor: '#fff'
            })
        }
        let oddAmtWei = ethers.utils.parseEther(oddAmt.toString());
        let evenAmtWei = ethers.utils.parseEther(evenAmt.toString());
        let amt = oddAmtWei.add(evenAmtWei);
        let oddRate = 50;
        if (!amt.eq(0)) {
            oddRate = oddAmtWei.mul(100).div(amt).toNumber();
        } else {
            oddRate = 50;
        }
        setOddStyle({
            opacity: 1,
            width: `${oddRate}%`
        })
        setEvenStyle({
            opacity: 1,
            width: `${100-oddRate}%`
        })
    }, [oddAmt, evenAmt]);

    return (
		<div className="bettable-info" style={borderStyle}>
			<div className="odd-bet-table" style={oddStyle}>
			</div>
            <div className="even-bet-table" style={evenStyle}>
			</div>
		</div>
	)
}

export default SliderThree;