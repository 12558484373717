import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import en_us from './locales/en-us.json';
import zh_cn from './locales/zh-cn.json';
import zh_tw from './locales/zh-tw.json';
import ko_kr from './locales/ko-kr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en_us',
    debug: process.env.NODE_ENV === 'production',
    resources: {
      en_us: {translation: en_us},
      ko_kr: {translation: ko_kr},
      zh_cn: {translation: zh_cn},
      zh_tw: {translation: zh_tw},
    },
    lng: "en_us",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;