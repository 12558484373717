import '../src/assets/icons/font-awesome.css';
import './App.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useEffect } from "react";
import AOS from 'aos';
import toast from 'react-hot-toast';
import { useEthers } from '@usedapp/core'
import { useTranslation } from 'react-i18next';

import { Route , Routes } from 'react-router-dom';
import routes from './pages/index'

export default function Home() {

  const { active, error } = useEthers();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration : 2000
    }); 
  }, []);

  useEffect(() => {
    if (active && error && error.message.indexOf("Unsupported chain") != -1) {
      console.log("error active", error.message)
      toast.error(t('slider.unsupportedNetwork'));
    }
  }, [error])

  return (
    <Routes >
      {
        routes.map((data,index) => (
            <Route exact={true} path={data.path} element={data.component} key={index} />
        ))
      }
    </Routes>
  );
}
