import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useEthers } from '@usedapp/core'
import { formatAddress } from '../../utils'
import { ethers } from 'ethers';
import Button from 'react-bootstrap/Button';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { getMyInvitor } from '../../fetch/fetchGame'
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { FaClone } from "react-icons/fa";
import useSetInvitor from '../../hooks/useSetInvitor';

const WEB_URL = process.env.WEB_URL;

const InvitationOne = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="invitation">
            <div>
                <h5>{t('invitation.rulesHead')}</h5>
                <div>
                    <p>{t('invitation.rulesTip1')}</p>
                    <p>{t('invitation.rulesTip2')}</p>
                    <p>{t('invitation.rulesTip3')}</p>
                    <p>{t('invitation.rulesTip4')}</p>
                </div>
            </div>
        </div>
    )
}

const InvitationTwo = () => {
    let [myInvitor, setMyInvitor] = useState("");
    let [newInvitor, setNewInvitor] = useState("");
    let [connectedWallet, setConnectedWallet] = useState();
    let {search} = useLocation();
    let mekaGame = useSelector((state) => state.chain.mekaGame)
    const { account: loginWallet, library } = useEthers();

    const { t, i18n } = useTranslation();

    const { state: txState, send: setInvitorSend } = useSetInvitor(mekaGame);
    const setInvitorAsync = async (invitor) => {
        return await setInvitorSend(invitor)
    }

    useEffect(() => {
        if (loginWallet) {
            async function getInvitor() {
                let invitorAddress = await getMyInvitor(mekaGame, library, loginWallet);
                setMyInvitor(invitorAddress);
                return invitorAddress;
            }
            getInvitor();
            setConnectedWallet(loginWallet);
        }
        if (search) {
            let parsed = queryString.parse(search);
            if (parsed && parsed.invitor) {
                setNewInvitor(parsed.invitor)
            }
        }
        if (txState.status === 'Fail' || txState.status === 'Exception') {
            toast.error(txState.errorMessage)
            // console.error(txState.errorMessage);
        }
    }, [loginWallet, library, mekaGame, search, txState]);

    const setInvitor = async (e) => {
        e.preventDefault();

        if (!ethers.utils.isAddress(newInvitor)) {
            toast.error(t('invitation.invalidAddress'));
            return;
        }

        let ret = await setInvitorAsync(newInvitor);
        if (ret && ret.confirmations >= 1) {
            toast.success(t('invitation.setInvitorSuccess'))
        }
    }

    const copyLink = (e) => {
        e.preventDefault();
        if (loginWallet === undefined) {
            toast.error(t("invitation.connectWalletTip"));
            return;
        }
        let formatUrl = queryString.stringifyUrl({url: WEB_URL, query: {invitor: loginWallet}});
        copy(formatUrl);
        toast.success(t("invitation.copyLinkSuccess"));
    }

    const copyAddress = (e) => {
        e.preventDefault();
        if (loginWallet === undefined) {
            toast.error(t("invitation.connectWalletTip"));
            return;
        }
        copy(loginWallet);
        toast.success(t("invitation.copyAddressSuccess"));
    }

    const copyInvitorAddress = (e) => {
        e.preventDefault();
        copy(myInvitor);
        toast.success(t("invitation.copyAddressSuccess"));
    }

    const renderInviteLink = () => {
        if (loginWallet === undefined) {
            return (
                <div className='invite-link'>
                    <span className='fs-18'>{t('invitation.inviteLinkConnectWalletTips')}</span>
                </div>
            )
        }
        return (
            <div>
                <div className='invite-link'>
                    <span className='fs-16' style={{marginRight: '10px'}}>{t('invitation.connectedWallet')}{formatAddress(connectedWallet)}</span>
                    <div>
                        <span><Button variant="link" size="sm" style={{paddingLeft: 0}} onClick={copyLink}>{t('invitation.copyLink')}</Button></span>
                        <span><Button variant="link" size="sm" style={{paddingRight: 0}} onClick={copyAddress}>{t('invitation.copyAddress')}</Button></span>
                    </div>
                </div>
                <span className='fs-16'>{t('invitation.inviteTips')}</span>
            </div>
        )
    }

    const renderInvitor = () => {
        if (loginWallet === undefined) {
            return (
                <div className='fs-18' style={{marginTop: '20px'}}>{t('invitation.connectWalletToInviteTips')}</div>
            )
        }
        if (myInvitor === '' || myInvitor === '0x0000000000000000000000000000000000000000') {
            return (
                <div>
                    <div className='fs-16' style={{marginTop: '20px'}}>{t('invitation.setInvitorTips')}</div>
                    <div className="button-action" style={{marginTop: '10px'}}>
                        <Form>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder={t('invitation.setInvitorInputTips')}
                                    style={{borderColor: '#fd562a', 
                                        borderRadius: '10px 0 0 10px', 
                                        background: 'rgba(255, 255, 255, 0.2)',
                                        color: '#fff'
                                    }}
                                    value={newInvitor}
                                    onChange={(v) => setNewInvitor(v.target.value)}
                                />
                                <Link className="btn-action style-2" onClick={setInvitor}>{t('invitation.setInvitorBtn')}</Link>
                            </InputGroup>
                        </Form>
                    </div>
                </div>
            )
        }
        return (
            <div style={{marginTop: '20px'}} className='d-flex justify-content-start align-items-center'>
                <span className='fs-16'>{t('invitation.myInvitorTips')}{formatAddress(myInvitor)}</span>
                <span onClick={copyInvitorAddress} className='fs-14' style={{marginLeft: '10px'}}><FaClone color='#fd562a'/></span>
            </div>
        )
    }

    return (
        <div className="invitation">
            {renderInviteLink()}
            {renderInvitor()}
        </div>
    )
}

const Invitation = () => {

    return (
        <section className="tf-section section-counter" id='invitation'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="invitation__body" data-aos="fade-down" data-aos-duration="1000">
                            <InvitationOne />
                            <InvitationTwo />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Invitation;