import { useContractFunction, useEthers } from '@usedapp/core'
import { ethers } from 'ethers'
import MekaGameJson from '../abi/MekaGame.json';

export default function useSetInvitor(contractAddress) {
  const { library: provider } = useEthers();
  const { state, send } = useContractFunction(
    contractAddress && provider && new ethers.Contract(contractAddress, MekaGameJson, provider), 
    'setInvitor', 
    { transactionName: 'Set Invitor' })
  return { state, send };
}