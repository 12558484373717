import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from './ScrollToTop';
import { Toaster } from 'react-hot-toast';
import store from './store'
import { Provider } from 'react-redux'
import './i18n';

import { DAppProvider, MetamaskConnector, Mumbai, Polygon, Arbitrum } from '@usedapp/core'
import {WalletConnectV2Connector} from '@usedapp/wallet-connect-v2-connector'
import { BlastTestnet } from "./BlastTestnetChain";

const dappConfig = {
  readOnlyChainId: Arbitrum.chainId,
  readOnlyUrls: {
    // [Mainnet.chainId]: 'https://mainnet.infura.io/v3/21050c3f9379491c817c36649d701e72',
    [Arbitrum.chainId]: 'https://arb1.arbitrum.io/rpc',
    [Mumbai.chainId]: 'https://polygon-mumbai-bor.publicnode.com',
    [Polygon.chainId]: 'https://polygon-rpc.com/',
    [BlastTestnet.chainId]: 'https://sepolia.blast.io'
  },
  networks: [Arbitrum, Mumbai, Polygon, BlastTestnet],
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnect: new WalletConnectV2Connector({
      projectId: '19e923f4396e5814cb392c6ea72bd386',
      chains: [Arbitrum, Mumbai, Polygon, BlastTestnet]
    })
  },
  // multicallVersion: 2,
}

const container = document.getElementById("root");
const root = createRoot(container);


const UseDappContext = () => {
  return (
    <DAppProvider config={dappConfig}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
        <Toaster/>
      </BrowserRouter>
    </DAppProvider>
  )
}

const Context = () => {
  return (
    <Provider store={store}>
      {/* <ThirdwebContext /> */}
      <UseDappContext />
    </Provider>
  )
}

root.render(
  <Suspense fallback="loading">
    <Context/>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
