import React, { useEffect , useState } from 'react';
import { useEtherBalance, useEthers, Arbitrum, Polygon, Mumbai } from '@usedapp/core'
import { ethers } from 'ethers';
import { formatAddress, formatBalance, getChainNativeSymbol } from '../../utils';
import { BlastTestnet } from '../../BlastTestnetChain';
import "./walletconnector.scss";
import Modal from 'react-bootstrap/Modal';
import metamask from "../../assets/images/metamask.png"
import walletconnect from '../../assets/images/wallet-connect.png'
import arbitrum from '../../assets/images/arbitrum.png'
import polygon from '../../assets/images/polygon.png'
import blast from '../../assets/images/blast.png'

const WalletConnector = () => {

  const { account, chainId, deactivate, activateBrowserWallet, switchNetwork } = useEthers();
  const userBalance = useEtherBalance(account);
  const [showWallet, setWalletModel] = useState(false);
  const [showDisconnectd, setDisconnectedModel] = useState(false);

  const switchNetworkAction = (chainId) => {
    switchNetwork(chainId);
    setWalletModel(false);
    setDisconnectedModel(false);
  }

  const connectMetamask = () => {
    activateBrowserWallet({type: 'metamask'});
    setWalletModel(false);
    setDisconnectedModel(false);
  }

  const connectWalletConnect = () => {
    activateBrowserWallet({type: 'walletConnect'});
    setWalletModel(false);
    setDisconnectedModel(false);
  }

  const renderWalletConnectModal = () => {
    return (
      <>
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showWallet}
          onHide={() => setWalletModel(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Select wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='wallet-btn' onClick={() => connectMetamask()}>
              <img src={metamask} width={40}  height={40} style={{marginRight: '12px'}} alt='Metamask'/>
              <span>Metamask Wallet</span>
            </div>
            <div className='wallet-btn' onClick={() => connectWalletConnect()}>
              <img src={walletconnect} width={40}  height={40} style={{marginRight: '12px'}} alt='WalletConnect'/>
              <span>WalletConnect</span>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  const renderWalletDisconnectModal = () => {
    return (
      <>
        <Modal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showDisconnectd}
          onHide={() => setDisconnectedModel(false)}
        >
          <Modal.Header>
            <Modal.Title>
              Switch Network
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='network-btn' onClick={() => switchNetworkAction(Arbitrum.chainId)}>
              <img src={arbitrum}  width={28}  height={28} style={{marginRight: '12px'}} alt='Arbitrum' />
              <span>Arbitrum One</span>
            </div>
            <div className='network-btn' onClick={() => switchNetworkAction(Polygon.chainId)}>
              <img src={polygon}  width={28}  height={28} style={{marginRight: '12px'}} alt='Polygon' />
              <span>Polygon</span>
            </div>
            <div className='network-btn' onClick={() => switchNetworkAction(Mumbai.chainId)}>
              <img src={polygon}  width={28}  height={28} style={{marginRight: '12px'}} alt='Mumbai' />
              <span>Mumbai</span>
            </div>
            <div className='network-btn' onClick={() => switchNetworkAction(BlastTestnet.chainId)}>
              <img src={blast}  width={28}  height={28} style={{marginRight: '12px'}} alt='Blast Sepolia' />
              <span>Blast Sepolia</span>
            </div>
            <div style={{borderTop: '1px solid'}}></div>
            <div className='disconnect-btn' onClick={() => deactivate()}>
              Disconnect Wallet
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  const renderWalletConnect = () => {
    return (
      <>
        <div className='connector' onClick={() => setWalletModel(true)}>
          <div>Connect Wallet</div>
        </div>
        {renderWalletConnectModal()}
      </>
    )
  }

  const renderNetworkIcon = () => {
    switch (chainId) {
      case Arbitrum.chainId:
        return (
          <>
            <img src={arbitrum} width={26} height={26} style={{marginRight: '10px'}} alt='arbitrum' />
          </>
        )
      case Polygon.chainId:
      case Mumbai.chainId:
        return (
          <>
            <img src={polygon}  width={26}  height={26} style={{marginRight: '10px'}} alt='Mumbai' />
          </>
        )
      case BlastTestnet.chainId:
        return (
          <>
            <img src={blast}  width={26}  height={26} style={{marginRight: '10px'}} alt='Blast Sepolia' />
          </>
        )
      default:
        return <></>
    }
  }

  const renderWalletDisconnect = () => {
    return (
      <>
        <div className='connector' onClick={() => setDisconnectedModel(true)}>
          <div className='connected-btn'>
            {renderNetworkIcon()}
            <div>
              <div style={{fontSize: '0.9rem'}}>{formatAddress(account)}</div>
              <div style={{fontSize: '0.7rem'}}>{formatBalance(userBalance && ethers.utils.formatEther(userBalance?.toString()))} {getChainNativeSymbol(chainId)}</div>
            </div>
          </div>
        </div>
        {renderWalletDisconnectModal()}
      </>
    )
  }

  const connector = () => {
    if (account) return renderWalletDisconnect()
    else return renderWalletConnect()
  }

  return (
    <>
      {connector()}
    </>
  )
}

export default WalletConnector;